import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBriefcase,
  faArrowRightFromBracket,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./MyAccount.css";
import usePost from "../../customhooks/UsePost";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
const ApplyNowModal = React.lazy(() =>
  import("../../components/jobs/ApplyNowModal")
);
import { Loader, useToaster, Notification, Placeholder } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import { Nav, Tab } from "react-bootstrap";
import UserContext from "../../app/context/UserContext";
import BasicDetails from "./BasicDetails";
import Loading from "../../Loder";
import FavouriteJobs from "./FavouriteJobs";
import axios from "axios";
const MyAccount = () => {
  const { post: logoutPost, data, isLoading, error } = usePost("/user/logout ");
  const {
    post: getguestuser,
    data: guestuserdata,
    isLoading: guestuserdataloaging,
  } = usePost("/user/guestuserdeatails ");

  let loaderDetails = useLoaderData();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [openLogin, setOpenLogin] = useState(false);
  const [guestuser, setguestuser] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("guest")) {
      setguestuser(true);
    }
  }, []);
  // useEffect(() => {
  //   if (!user) {
  //     setOpenLogin(true);
  //   } else {
  //     setOpenLogin(false);
  //   }
  // }, [!isLoading,user]);
  const toaster = useToaster();

  async function logout() {
    try {
      const response = await logoutPost();
      // console.log(response, "responseeee")

      if (response) {
        toaster.push(
          <Notification
            type={"success"}
            header={t("success", { defaultValue: "Success" })}
            closable
          >
            {/* <Trans
              i18nKey="WelcomeBack!YourenowLoggedin"
              defaults="Welcome back! You're now logged in."
            /> */}
            {t("logoutSuccessful", {
              defaultValue: "Logout successful",
            })}
          </Notification>,
          { placement: "topEnd" }
        );
        window.location.replace("/jobs");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }
  const backbtn = () => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    // window.location.replace("/jobs");
    const currentOrigin = window.location.pathname;
    return currentOrigin.includes("careers")
      ? window.location.replace(parameters.get("redirect"))
      : window.location.replace("/jobs");
  };
  const [key, setKey] = useState("Basicdetails");

  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    setLoadingUser(true);

    setTimeout(() => {
      setLoadingUser(false);
    }, 2000);
  }, [user]);
  const [cookiesresume, setcookiesresume] = useState("");
  const getcokies = async () => {
    try {
      let cookieDetails = await axios.get(
        window.location.origin + "/jsv4/webapi/getcookie"
      );
      cookieDetails = cookieDetails?.data;
      // console.log("cookieDetailscookieDetails", cookieDetails);

      if (cookieDetails["Guest_User_ResumeID"]) {
        setcookiesresume(cookieDetails["Guest_User_ResumeID"]);
      }
    } catch (error) {
      console.log("Error in getCookies : ", error);
    }
  };
  useEffect(() => {
    if (guestuser) {
      getcokies();
    }
  }, [guestuser]);
  // useEffect(() => {
  //   if (cookiesresume !== "") {
  //     getguestuser({ resumeID: cookiesresume })
  //       .then((res) => {
  //         if (res?.status) {
  //           setUser(res?.result);
  //           console.log(res?.result, "usegetpercentage");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error while fetching custom layout:", error);
  //       });
  //   }
  // }, [cookiesresume]);

  const [payload, setPayload] = useState(null);

  useEffect(() => {
    let queryparams = new URLSearchParams(location.search);
   
    if (queryparams.has("rid") && !user) {
      setPayload({
        resumeID: queryparams.get("rid"),
        target: "urlRid",
        companyID: loaderDetails?.companyID,
      });
    } else if (guestuser && !user && cookiesresume !== "") {
      setPayload({
        resumeID: cookiesresume,
        companyID: loaderDetails?.companyID,
      });
     
    }
  
  }, [location.search,cookiesresume]);

  useEffect(() => {
    if (payload != null) {
      getguestuser(payload).then((res) => {
        setUser(res?.result);

        const newResumeID = res?.result?.resumeID;

        if (newResumeID) {
          const currentURL = new URL(window.location.href);
          currentURL.searchParams.set('rid', newResumeID);
          window.history.pushState(null, '', currentURL.toString());
        }
      });
    }
  }, [payload]);

  return (
    <>
      {/* <div className="">
        <div className="row">
          <div className="col-sm-12 col-md-2 col-lg-2 p-0 ps-2 pe-2">
            <div className="card">
              <div className="row">
                <div className="col-2 mt-2 d-flex align-items-center">
                  <button className="backbutton" onClick={backbtn}>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="arrowIcon mx-2"
                    />
                  </button>
                </div>
                <div className="col-10 mt-2 basic-details d-flex align-items-center">
                  <b> {user?.fullName} </b>
                </div>
              </div>

              <div className="px-2 pt-2">
                <div className="py-2 nav-item ">
                  <NavLink
                    to="/myaccount/basicdetails/profilebasicdetails"
                    className={`text-muted text-decoration-none navlinks nav-link p-2 ps-3 ${
                      location.pathname.includes("/myaccount/basicdetails")
                        ? "active"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faUser} className="iconUser me-2" />
                    <span className="mt-3">Account Details</span>
                  </NavLink>
                </div>
                <div className="py-2 nav-item ">
                  <NavLink
                    to="/myaccount/favouritejobs"
                    className={`text-muted text-decoration-none navlinks nav-link p-2 ps-3 ${
                      location.pathname === "/myaccount/favouritejobs"
                        ? "active"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="iconUser me-2"
                    />
                    <span className="mt-3"> Favorite Jobs</span>
                  </NavLink>
                </div>
                <div className="py-2 nav-item ">
                  <button
                    className={`text-muted text-decoration-none navlinks nav-link p-2 ps-3`}
                    onClick={logout}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      className="iconUser me-2"
                    />
                    <span className="mt-3"> Logout</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-10 col-lg-10  pe-2 ps-2 ">
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>  */}

      {loadingUser ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader
            size="sm"
            center
            content={<Trans i18nKey="loading" defaults="Loading" />}
            vertical
          />
        </div>
      ) : (
        <>
          {openLogin && !loadingUser ? (
            <ApplyNowModal
              openModal={openLogin}
              setOpenModal={setOpenLogin}
              from={"myaccount"}
            />
          ) : (
            <div>
              <Tab.Container
                defaultActiveKey="Basicdetails"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                }}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-2 col-lg-2 p-0 ps-2 pe-2">
                    <div className="card">
                      <div className="row">
                        <div className="col-2 mt-2 d-flex align-items-center">
                          <button className="backbutton" onClick={backbtn}>
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              className="arrowIcon mx-2"
                            />
                          </button>
                        </div>
                        <div className="col-10 mt-2 basic-details d-flex align-items-center">
                          <b> {user?.fullName} </b>
                        </div>
                      </div>
                      <div className="w-100">
                        <Nav className="pt-3 flex-column text-muted">
                          <Nav.Item className="py-2  px-1 ">
                            <Nav.Link
                              eventKey="Basicdetails"
                              className={`navtabhover text-decoration-none p-2 ps-3 text-muted ${
                                key == "Basicdetails" ? "navlinks" : ""
                              } `}
                            >
                              <FontAwesomeIcon
                                icon={faUser}
                                className="iconUser me-2"
                              />
                              <span className="mt-3">
                                <Trans
                                  i18nKey="accountDetails"
                                  defaults="Account Details"
                                />
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                          {!guestuser && (
                            <>
                              <Nav.Item className="py-2 nav-item  px-1 ">
                                <Nav.Link
                                  eventKey="FaviouteJobs"
                                  className={`navtabhover text-decoration-none p-2 ps-3 text-muted ${
                                    key == "FaviouteJobs" ? "navlinks" : ""
                                  } `}
                                >
                                  <FontAwesomeIcon
                                    icon={faBriefcase}
                                    className="iconUser me-2"
                                  />
                                  <span className="mt-3">
                                    <Trans
                                      i18nKey="favoriteJobs"
                                      defaults="Favorite Jobs"
                                    />
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="py-2 nav-item  px-1">
                                <Nav.Link
                                  eventKey="logout"
                                  className={`navtabhover text-decoration-none p-2 ps-3 text-muted ${
                                    key == "logout" ? "navlinks" : ""
                                  } `}
                                  onClick={logout}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowRightFromBracket}
                                    className="iconUser me-2"
                                  />
                                  <span className="mt-3">
                                    <Trans i18nKey="logout" defaults="Logout" />
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                        </Nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-10 col-lg-10  pe-2 ps-2 ">
                    <Tab.Content>
                      <Tab.Pane eventKey="Basicdetails">
                        {key == "Basicdetails" && (
                          <React.Suspense fallback={<Loading />}>
                            <BasicDetails key={"Basicdetails"} />
                          </React.Suspense>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="FaviouteJobs">
                        {key == "FaviouteJobs" && (
                          <React.Suspense fallback={<Loading />}>
                            <FavouriteJobs key={"FaviouteJobs"} />
                          </React.Suspense>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyAccount;
