import React, { useEffect, useContext, useState } from "react";
import { Form, Modal, Button, Notification, toaster, Checkbox } from "rsuite";
// import ExperienceModel from "./ExperienceModel";
import ExperienceModel from "../../../profile/form/ExperienceModel";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import { t } from "i18next";
import usePost from "../../../../customhooks/UsePost";
import { useForm } from "react-hook-form";
import UserContext from "../../../../app/context/UserContext";

const ExperienceSessionEdit = ({
  open,
  setOpen,
  experienceRowsList,
  resumeId,
  experienceList,
  experienceRefetch,
  standardFieldsList,
  companyID,
  clientID,
  setUpdateResumeDataData,
  setscrollRef,
  resumeData,
  type
}) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    clearErrors,
    setError,
    watch,
    getValues,
  } = useForm();
  const handleExperienceClose = () => {
    setOpen(false);
    clearErrors();
    if(type != "referalresume"){
    setscrollRef(null);
    }
  };

  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");
  const { user, setUserDetails } = useContext(UserContext);

  const submitExperienceDetails = () => {
    let data = getValues();
    let flag = true;
    let experienceDetails = [];
    if (data?.experience?.length > 0) {
      console.log(data, "submitExperienceDetailsjdbsjdfj");
      for (let i = 0; i < data?.experience?.length; i++) {
        let experienceObj = {
          company: "",
          jobTitle: "",
          workSchedule: "",
          startDate: "",
          endDate: "",
          supervisor: "",
          description: "",
          status: false,
          location: "",
          skills: [],
        };
        if (
          data?.experience[i].endDate != "" &&
          data?.experience[i].startDate != ""
        ) {
          if (
            DateTime.fromJSDate(data?.experience[i].startDate).toMillis() >
            DateTime.fromJSDate(data?.experience[i].endDate).toMillis()
          ) {
            flag = false;
            setError(
              `experience[${i}].endDate`,
              {
                type: "manual",
                message: t("endDateShouldBeGreaterThan", {
                  defaultValue: "End date should be greater than start date",
                }),
              },
              { shouldFocus: true }
            );
          } else {
            flag = flag ? true : false;
            clearErrors(`experience[${i}].endDate`);
          }
        }
        if (
          data?.experience[i].startDate == null ||
          data?.experience[i].startDate == "" ||
          data?.experience[i].startDate == undefined
        ) {
          experienceObj.startDate = "";
        } else {
          experienceObj.startDate = DateTime.fromJSDate(
            data?.experience[i].startDate
          ).toFormat("yyyy-MM-dd");
        }
        if (
          data?.experience[i].endDate == null ||
          data?.experience[i].endDate == "" ||
          data?.experience[i].endDate == undefined
        ) {
          experienceObj.endDate = "";
        } else {
          experienceObj.endDate = DateTime.fromJSDate(
            data?.experience[i].endDate
          ).toFormat("yyyy-MM-dd");
        }
        experienceObj.company = data?.experience[i].company;
        experienceObj.jobTitle = data?.experience[i].jobTitle;
        experienceObj.workSchedule = data?.experience[i].workSchedule;
        experienceObj.supervisor = data?.experience[i].supervisor;
        experienceObj.description = data?.experience[i].description;
        experienceObj.status = data?.experience[i].status;
        experienceObj.location = data?.experience[i].location;
        experienceObj.locationObJ = data?.experience[i]?.locationObJ
          ? data?.experience[i]?.locationObJ
          : {};
        experienceObj.skills = data?.experience[i]?.skills;
        experienceDetails.push(experienceObj);
      }
      if (flag) {
        // console.log(experienceDetails?.flatMap((x)=>x?.skills));
        const expenceskills = experienceDetails?.flatMap((x) => x?.skills);
        const summaryskills =
          resumeData?.resume?.professionalQualification?.skills?.map((x) => {
            return {
              text: x?.text,
              yearsOfExperience: x?.yearsOfExperience,
            };
          });
        const completeskills = [...expenceskills, ...summaryskills];
        const uniqueData = completeskills.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.text === obj.text)
        );

        console.log(uniqueData);

        updateResumeDetails({
          target: "experience",
          resumeID: resumeId,
          user: user,
          companyID: companyID,
          clientID: clientID,
          getValues: {
            skills: uniqueData,
            experience: experienceDetails,
            sectionName: standardFieldsList?.sectionName
              ? standardFieldsList?.sectionName
              : "Experience",
          },
          type: "Profile Edited",
        })
          .then((res) => {
            if (res) {
              setUpdateResumeDataData(res);
              handleExperienceClose();
            }
          })
          .catch((error) => {
            console.error("Error while fetching Resume:", error);
          });
      } else {
        toaster.push(
          <Notification
            type={"warning"}
            header={<Trans i18nKey="warning" defaults="Warning" />}
            closable
          >
            <Trans
              i18nKey="endDateShouldBeGreaterThanStartDate"
              defaults="End Date Should Be Greater Than Start Date"
            />
          </Notification>,
          {
            placement: "topEnd",
          }
        );
      }
    } else {
      updateResumeDetails({
        target: "experience",
        resumeID: resumeId,
        companyID: companyID,
        user: user,
        clientID: clientID,
        getValues: {
          experience: [],
          sectionName: standardFieldsList?.sectionName
            ? standardFieldsList?.sectionName
            : "Experience",
        },
        type: "Profile Edited",
      })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            handleExperienceClose();
          }
        })

        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  };
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    if (open) {
      setSkip(true);
    }
  }, [open]);
  const [checked, setchecked] = useState(false);
  const [showapprove, setshowapprove] = useState(false);
  return (
    <>
      <Modal size={"full"} open={open} onClose={handleExperienceClose}>
        <Modal.Header>
          <Modal.Title>
            {standardFieldsList?.i18nKey != "" ? (
              t(standardFieldsList?.i18nKey, {
                defaultValue: standardFieldsList?.sectionName,
              })
            ) : (
              <Trans i18nKey="experience" defaults="Experience" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100">
            <div className="col-sm-12 col-md-12">
              <ExperienceModel
                control={control}
                register={register}
                target={"editProfile"}
                watch={watch}
                clearErrors={clearErrors}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                experienceList={experienceList}
                experienceRowsList={experienceRowsList}
                setError={setError}
                isDirty={isDirty}
                isValid={isValid}
                handleSubmit={handleSubmit}
                skip={skip}
                setSkip={setSkip}
                open={open}
                setshowapprove={setshowapprove}
                type={type}
                companyID={companyID}
                from={"BasicDetails"}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between">
              <span>
                <button
                  className="btn btn-sm  btn-light"
                  onClick={handleExperienceClose}
                >
                  <Trans i18nKey="cancel" defaults="Cancel" />
                </button>
              </span>
              <span>
                {showapprove && (
                  <Checkbox
                    className="me-2 text-muted"
                    defaultValue={checked}
                    onChange={() => {
                      if (checked) {
                        setchecked(false);
                      } else {
                        setchecked(true);
                      }
                    }}
                  >
                    I approve the Experience template for use.
                  </Checkbox>
                )}
                {/* <Checkbox ><b>I approve</b> </Checkbox> */}
                <button
                  className="btn btn-sm btn-primary"
                  disabled={
                    UpdateResumeDataDataLoading || showapprove
                      ? !checked
                      : false
                  }
                  onClick={handleSubmit(submitExperienceDetails)}
                >
                  {UpdateResumeDataDataLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="px-1">
                    <Trans i18nKey="save" defaults="Save" />
                  </span>
                </button>
              </span>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExperienceSessionEdit;